import React from "react"

import Layout from '../components/layout'

export default function ThankYou() {
  return (
    <Layout>
      <div className="text-center pt-5">
        <h2>Thank you!</h2>
        <p>
          You will be contacted via email as soon as possible (usually within 24 hours).
        </p>
        <br /><br />
      </div>
    </Layout>
  )
}
